import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportService from "./reports-service";
// import Utility from "../../../shared/utility";
import exportLimit from "../../common/export-limit.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      max50Rule: [(v) => (v || "").length <= 30 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      assetID: "",
      disableAssetID: false,
      orderInfo: "",
      disableOrderInfo: false,
      trackingInfo: "",
      disableTrackingInfo: false,
      searchButton: false,
      searchAssetHistory: "",
      assetHistoryList: [],
      totalRecords: 0,
      showData: false,
      page: 0,
      pageCount: 0,
      apiObj: {
        user_key1: 0,
        orderinformation: "",
        assetids: "",
        trackinginformation: "",
        testbit: 0,
        RecordCount: 0,
        startindex: 0,
        PageSize: 0,
        guid: "",
      },
      assetHistoryHeaders: [
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Customer", value: "customer", class: "primary customwhite--text" },
        { text: "Vendor", value: "vendor", class: "primary customwhite--text" },
        { text: "Part No", value: "part_num", class: "primary customwhite--text" },
        { text: "BCN", value: "bcn", class: "primary customwhite--text" },
        { text: "Serial No", value: "serialno", class: "primary customwhite--text" },
        { text: "ESN", value: "esn", class: "primary customwhite--text" },
        { text: "RLP ID", value: "license_plate", class: "primary customwhite--text" },
        { text: "Search Key", value: "search_param", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.assetID = "";
      this.disableAssetID = false;
      this.orderInfo = "";
      this.disableOrderInfo = false;
      this.trackingInfo = "";
      this.disableTrackingInfo = false;
      this.searchButton = false;
      this.searchAssetHistory = "";
      this.assetHistoryList = [];
      this.totalRecords = 0;
      this.showData = false;
      this.page = 0;
      this.pageCount = 0;
      this.apiObj = {
        user_key1: 0,
        orderinformation: "",
        assetids: "",
        trackinginformation: "",
        testbit: 0,
        RecordCount: 0,
        startindex: 0,
        PageSize: 0,
        guid: "",
      };
      this.$refs.assetSearchForm.resetValidation();
    },
    //Asset ID Search
    async searchAssetID() {
      if (this.assetID !== undefined && this.assetID !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.assetids = this.assetID;
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Asset ID
    inputAssetID() {
      this.assetID = this.assetID.toUpperCase();
      this.trackingInfo = "";
      this.orderInfo = "";
      this.disableTrackingInfo = true;
      this.disableOrderInfo = true;
    },
    //Order Information Search
    async searchOrderInfo() {
      if (this.orderInfo !== undefined && this.orderInfo !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.orderinformation = this.orderInfo;
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Order Information
    inputOrderInfo() {
      this.orderInfo = this.orderInfo.toUpperCase();
      this.trackingInfo = "";
      this.assetID = "";
      this.disableTrackingInfo = true;
      this.disableAssetID = true;
    },
    //Tracking Information Search
    async searchTrackingInfo() {
      if (this.trackingInfo !== undefined && this.trackingInfo !== "") {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.trackinginformation = this.trackingInfo;
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      } else {
        this.showData = false;
        this.totalRecords = 0;
        this.assetHistoryList = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any value",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Input event of Tracking
    inputTracking() {
      this.trackingInfo = this.trackingInfo.toUpperCase();
      this.orderInfo = "";
      this.assetID = "";
      this.disableAssetID = true;
      this.disableOrderInfo = true;
    },
    //On search button click
    async onSearch(page) {
      if (!this.disableAssetID) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.assetids = this.assetID;
        if (page !== undefined) {
          this.apiObj.startindex = page;
        }
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      }
      if (!this.disableOrderInfo) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.orderinformation = this.orderInfo;
        if (page !== undefined) {
          this.apiObj.startindex = page;
        }
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      }
      if (!this.disableTrackingInfo) {
        this.apiObj.user_key1 = parseInt(this.userId);
        this.apiObj.trackinginformation = this.trackingInfo;
        if (page !== undefined) {
          this.apiObj.startindex = page;
        }
        let showMessage = false;
        let data = await reportService.assetHistoryReportGrid("post", this.apiObj, showMessage);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.totalRecords = data.TotalRecords;
            this.pageCount = data.TotalPages;
            if (data.Result.length == 0) {
              this.assetHistoryList = [];
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No data available based on criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              this.assetHistoryList = data.Result;
              this.showData = true;
            }
          }
        }
      }
    },
    //Cancel search event
    cancelSearch() {
      this.assetID = "";
      this.disableAssetID = false;
      this.orderInfo = "";
      this.disableOrderInfo = false;
      this.trackingInfo = "";
      this.disableTrackingInfo = false;
      this.searchButton = false;
      this.apiObj = {
        user_id: 0,
        assetids: "",
        orderinformation: "",
        trackinginformation: "",
        startindex: 0,
        PageSize: 0,
        recordcount: 0,
        guid: "",
      };
      this.totalRecords = 0;
      this.assetHistoryList = [];
      this.showData = false;
      this.$refs.assetSearchForm.resetValidation();
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
